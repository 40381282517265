import {  useMemo, useState ,useEffect} from 'react'
import { createTheme } from 'react-data-table-component'
import { datatableTheme } from '../../data/dataTableTheme'
import { useDispatch ,useSelector } from 'react-redux'
import FieldInput from "../../components/snippets/FieldInput"
import { useFormik } from "formik"
import * as Yup from 'yup'
import API from '../../axios/API'
import { setAlert } from '../../store/theme/actions'
import { setDemographic } from '../../store/auth/actions'
import { useTranslation } from "react-i18next"



createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)

const Editcarereceiver = ({ details, onClose }) => {

    const [formLoading, setFormLoading] = useState(false)
    const [editForm] = useState(true)
    const [dataLoaded] = useState(true)
    const dispatch = useDispatch()
    const demographiccount = useSelector(state =>state.auth.demographiccount)
    const {t} = useTranslation()
    let orgId = localStorage.getItem('orgid')
    let subId = localStorage.getItem('subId')
    let assessType1 = localStorage.getItem('assessType')
    const [subreq,setSubReq] = useState(false)
    const [assessmentTypeId1,setAssessmentTypeId1] = useState(false)
    const [errWarning,setErrWarning] = useState(false)
    const [customsubWarn,setCustomSubWarn] = useState()
    const [nextButtonDisable,setNextButtonDisable] = useState(false)
    const userDetails = useSelector(state => state.auth.userDetails)
    const [tempCrSub,setTempCrSub] = useState()
    const formData = useMemo(() => {
        return { ...details }
    },[details])

    useEffect(() => {
        if(subId === 'true') {
            setSubReq(true)
        }
        if(assessType1 === 'true'){
            setAssessmentTypeId1(true)
        }
    },[]) // eslint-disable-line

    Yup.addMethod(Yup.string,'validateName',function(){
        return this.test(t('onlyAlphabet and numbers are allowed'),t('Only Alphabets and numbers allowed'),(value)=>{
            if(value === undefined || value === '')return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })
    Yup.addMethod(Yup.string, 'EmptySpace', function () {
        return this.test(t('Empty space at begining'),t('Space at beginning is not allowed'), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })
    Yup.addMethod(Yup.string, 'LengthSubId', function(){
        return this.test(t('SubscriberId must be the length of either 9 or 14'),t('SubscriberId must be the length of either 9 or 14'),(value)=>{
            if(value === undefined || value == '' || value.length === 0 || value === null || value.length === 9 || value.length === 14) return true
            if(value.length >= 10 && value.length < 14) return false  
        })
    })

    const profileFormSchema = Yup.object().shape({
        firstName: Yup.string().nullable().max(50).validateName().required(t("Firstname is a required field")),
        lastName: Yup.string().nullable().max(50).validateName().required(t("Lastname is a required field")),
        subscriberId: subreq && assessmentTypeId1 && userDetails.env !=='MOLINA' ? Yup.string().EmptySpace().length(9,t("It should have nine numeric values")).matches(/^[0-9]*$/,t("Subscriber Id should numeric value")).required(t("Subscriber Id is a required field")): !subreq && assessmentTypeId1 && userDetails.env !=='MOLINA' ? Yup.string().EmptySpace().length(9,t("It should have nine numeric values")).matches(/^[0-9]*$/,t("Subscriber Id should numeric value")): subreq && userDetails.env !=='MOLINA' ? Yup.string().EmptySpace().length(9,t("It should have nine alpha - numeric values")).matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")).required(t("Subscriber Id is a required field")): userDetails.env ==='MOLINA' ? Yup.string().EmptySpace().LengthSubId().min(9).max(14).matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")):Yup.string().EmptySpace().length(9,t("It should have nine alpha - numeric values")).matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric"))
    })


    const getSubscriberId = (value) => {
        var regex = /^[a-zA-Z0-9_]*$/;
        if(((userDetails.env !=='MOLINA' && value.length === 9) || (userDetails.env ==='MOLINA' && (value.length === 9 || value.length === 14))) && regex.test(value) && tempCrSub !== value){
            API.get(`intake/organizations/${localStorage.getItem('orgid')}/clients/${details.crId}/SubIdValidate?subId=${value}`).then((res) => {
                if (res.status === 200) {
                    setErrWarning(false)
                    setNextButtonDisable(false)
                }
            }).catch(err => {
                if (err.response.status === 406) {
                    dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true })) 
                    setErrWarning(true)
                    setNextButtonDisable(true)
                    setCustomSubWarn(err.response.data.errorMessage)
                    // profileForm.setValues({...profileForm.values,...profileForm.values,subscriberId:""})   
                }
            })

        }else{
            setErrWarning(false)
            setNextButtonDisable(false)
        }
        setTempCrSub(value)
    }


    const profileForm = useFormik({
        initialValues: formData,
        validationSchema: profileFormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
            setFormLoading(true)
            updateForm()
            setFormLoading(false)
        }
    })
    
    const Saveclient =()=>{
        setNextButtonDisable(true)
        profileForm.submitForm()
    }

    const updateForm = ()=>{
        API.patch(`care-receivers/${details.crId}?orgId=${orgId}`,profileForm.values).then((res)=>{
            if(res.status === 200){
                dispatch(setAlert({ title: t('Success'),subtitle:t('CareReceiver updated successfully'), active: true, type: 'success'}))
                onClose();
                dispatch(setDemographic(demographiccount+1))
            }
        }).catch(err => {
            if (err.response.status === 406) {
                dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
               
            } else {
                dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), type: 'error', active: true }))
            }
        })
    }

    return (
        <div className="py-2 w-full">
            <div className="px-4 mt-2">
                <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3">
                    <FieldInput ariaLabel={t("First Name is a required field")} hideData={true} loading={formLoading} label={t("First Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('firstName')} fieldProps={profileForm.getFieldProps('firstName')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldInput ariaLabel={t("Last Name is a required field")} hideData={true} loading={formLoading} label={t("Last Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('lastName')} fieldProps={profileForm.getFieldProps('lastName')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldInput ariaLabel={t("Subscriber Id is a required field")} loading={formLoading} label={t("Subscriber Id")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('subscriberId')} fieldProps={profileForm.getFieldProps('subscriberId')} edit={editForm} dataLoaded={dataLoaded} ml={true} length={userDetails.env ==='MOLINA'?'14':'9'} onChange={(ev) => getSubscriberId(ev)} onPaste={(ev) => getSubscriberId(ev)} err={errWarning} customErrmsg={customsubWarn} />
                </div>
                {editForm &&
                    <div className="flex justify-between mt-7 mx-5">
                        <button disabled={formLoading} className="flex items-center relative py-1 px-4 ml-2 mr-2 rounded ring-1 bg-red-700 hover:bg-opacity-80 text-white" onClick={onClose}>{t("Cancel")}</button>
                        <button disabled={formLoading || nextButtonDisable} className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white"  onClick={() => Saveclient()}>{t("Save")}</button>
                    </div>
                }
            </div>
        </div>
    )
}

export default Editcarereceiver